export const m = {




  loginTitle: "Email",
  loginPassword: "Password",
  homelecture:"Lecture",
  homegonggao:"Notices & Information",
  hometiaozhan:"Events",
  homepaihang:"Rankings",
  homegerenxinxi:"Personal Information",
  homexiaoxi:"Contact Us",
  hometiaozhanchengji:"Results",
  homeanliyanshi:"演示安排",
  infolianxiyouxiang:"My Email",
  infoshangchuantouxiang:"Upload profile picture",
  messageTitle:"Messages",
  messageProjectCode:"Exams",
  messageType:"Message Types",
  messagexuesu:"About Academic",
  messageliucheng:"About Process",
  messagexitong:"About System Operation",
  messageshuju:"About Score",
  messagejiangxiang:"About Award and Certificate",
  messageqita:"Others",
  messagetijiao:"Send",
  messagexiaoxitixing:"Message type needs to be selected",
  messagesendsuccess:"Send message successfully",
  messagehuifu:"Reply：",
  xiazaizhengshu:"Download the certificate",
  myzhengshu:"My E-Certificates",
  mychengjidan:"My E-Transcript",
  mydaochupdf:"Save as a PDF file",

  cansaikemu:"Entry Subjects",
  qingshuruneirong:"Please type in your questions",
  kaishishijian:"Start time (UTC)",
  weikaishi:"Not started",
  jinxingzhong:"Testing",
  yiwancheng:"Completed",
  jinrukaochang:"Enter Test",
   
  readyTip:"Please do equipment check before entering the test:",
  readyTip1:"The authorization on this preparation page is for equipment check only. You need re-authorize your camera and screen sharing after you enter the test page.",
  readyTip2:"Camera Sharing Check",
  readyTip3:"Reopen",
  readyTip4:"[Full Screen Sharing*]Check",
  readyTip5:"[ID Document **]Photo Upload",
  readyTip6:"View uploaded photos",
  readyTip7:"Upload photo proof of identity",
  readyTip8:"*You must share the [ full screen ],but not only single window or browser tab.",
  readyTip9:"**ID Document refers to any official document that proves a test taker's identity ( e.g. National ID card ,driver license ,School ID card ,National Security Card ,Passport ,etc.).",
  readyTip10:"When photographing make sure the photo and name side of your ID document is clearly visible in front of the camera, To avoid losing focus, do not put it too close to the camera.",
  readyTip11:"The photograph is only for use of test proctoring and it will be deleted from the server after 5 business days .",
  readyTip12:"Attention: you must use a desktop computer (Windows or Mac). Mobile phones and tables are not supported. You also must use Google Browser Chrome and authorize your computer and browser to share your camera and full screen.",
  readyTip13:"Do not how to authorize your camera and screen sharing? Please view",
  readyTip14:"[Equipment Setting Guide]",
  readyTip15:"All screen cuttings will be recorded and reviewed by proctors.",
  readyTip16:"Enter Test",
  readyTip17:"Take",
  readyTip18:"Preview",
  readyTip19:"Photo",
  readyTip20:"Note: please put your ID document in the middle and keep the camera focused with photo and name clearly visible. Press 'take' button to confirm",
  readyTip21:"Retake",
  readyTip22:"Submit",

  zixunxiaoxi:"Message",


  cailiaotihi:"The left half of the page is for the test material, click 'Previous Page/Next Page' to turn the page of the materials . More function buttons are below at the right bottom of the page. Please scroll down the page to the bottom if you cannot see them.",


  jiankaozhong:"Proctoring",
  shengyushijian:"Remaining examination time",
  chakandatu:"Click for larger image",
  jiazaishibai:"Material failed to load. Please click on the \"Reload\" button below to try again.",

  zuhouyiti:"You have reached the last question.",
  diyiti:"You have reached the first question.",

  diyiye:"You have reached the first page.",
  zuihouyiye:"You have reached the last page.",
  yida:"Answered",
  wanchengle:"Please note that you have already completed this test. No re-entry is allowed.",

  examTip:"Screen switch monitoring is in place and will be reviewed by the invigilator. Any suspected cheating will be assessed manually based on your screen-sharing logs. If non-intentional switches occur, like pop-ups or accidental off-screen clicks, simply continue with the exam as usual.",
  


  chonglian:"Proctoring connection is interrupted, please click to reconnect.",



  luzhishibai:"Screen sharing authorization failed",
  huoqushexiangtoushibai:"Camera sharing authorization failed",
  pingmuluzhi:"Your browser does not support screen sharing",
  shexiangtouzhichi:"Your browser does not support camera sharing.",


  jianceyouwenti:"The system detects your camera and screen sharing is disrupted. You can not continue under this condition.",

  kaoshitishi:"You are currently in a test. If you exit from the current page, your test wil be automatically ended. Are you sure you want to exit?",
  kaoshituichutixing:"Confirm to EXIT",
  erciqeuretuichu:"Are you sure you want to exit?",

  wangluoyichang:"Your connection is lost. Please reconnect and refresh the page.",
  wangluoyilianjie:"Connected!",


  idshangchuanshibai:"ID Photo Upload failed. Please try again!",
  idshangchaunchenggong:"ID Photo Upload Success",
  yijingwanchengkaoshi:"You have already completed the test.",
  shebieyouwenti:"You can not enter the test as your equipment is not set up correctly.",
  qingxianshangchuanshenfen:"Please upload your ID document photo first.",
  kaoshishijianmeidao:"The test has not started. Please wait for a while.",
  koashishijianguoshiwu:"The test entry time is over. You cannot enter the test room now.",
  huoququanxianzhong:"Getting camera authorization..",
  huoqupingmufenxiangzhong:"Getting screen authorization...",
  qingxuanzhequanbupingmu:"Please choose to share the full screen",
  pingmugongxiangshiabia:"Screen authorization failed",



  kaoshijieshu:"The exam has ended",
  chengjiweigongbu:"E-transcripts are not yet available, please check back after you receive the result notification email",


  quxiaobtn: "Cancel",


  systemName: "Project Guide System",
  systemDesc1: "The Project Guide System is",
  systemDesc2: "a professional management platform",
  systemDesc3: "for your working projects.",
  accountName: "account",
  password: "password",
  login: "Login",
  pleaseChoose: "Please choose",
  loginType: "loginType",
  jiaoshiguanli: "Teacher",
  xueshengguanli: "Student",
  xiangmuguanli: "Project",
  xufeiguanli: "Renewal",
  benxiaomoban: "My Templates",
  mobanshangdian: "Template Store",
  xitongshezhi: "Settings",
  zhanghaoshezhi: "Settings",
  xuexiaoxinxi: "Information",
  tishi: "Notice",
  renyuan: "Personnel",
  shezhi: "Settings",
  xiangmu: "Project",
  moban: "Template",
  fanhui: "Back to home",
  queding: "Confirm",
  baocun: "Save",
  quxiao: "Cancel",
  sousuo: "Search",
  zuijingengxinshijian: "Latest Update On",
  bianji: "Edit ",
  shanchu: "Delete",
  zhankaimingxi: "Unfold",
  yincangmingxi: "Fold",
  zhankaiquanbumokuaimingxi: "Unfold All Modules",
  yincangquanbumokuaimingxi: "Fold All Modules",
  qiehuanzhiqingshuangshitu: "Switch to Clean View",
  qiehuanzhiliebiaoshitu: "Switch to List View",
  qiehuanzhibiaogeshitu: "Switch to Table View",
  xinzengrenwumokuai: "New Module",
  gengduocaozuo: "More Actions",
  caozuo: "Actions",
  bianjimobanxinxi: "Edit Template Info",
  mokuaipaixutiaozheng: "Sort Modules",
  pingfenbiaoshezhi: "Edit Evaluation Form",
  mokuaimingxi: "Module Details",
  zaicichuxinzengmokuai: "Add a New Module Here",
  yaoqiuqingdan: "Check List",
  ziyuanqingdan: "Resource List",
  renwushuoming: "Instruction",
  jiaofuchengguo: "Deliverable",
  shi: "Yes",
  fou: "No",
  yiji: "Level One",
  erji: "Level Two",
  sanji: "Level Three",
  changwenbenshuru: "Multi-line Text Input",
  duanwenbenshuru: "Single-line Text Input",
  xialaxuanzeshuru: "Select from Drop-down Menu",
  chunwenzixinxizhanshi: "Pure Text Display",
  shangchuanwenjian: "File Upload",
  zhiliangjiancha: "Quality Check",
  renwumokuaiming: "Module Name",
  renwumokuaijibie: "Module Level",
  mokuaishuruleixing: "User Input Type",
  zengjiaxuanxiang: "Add Options",
  xialaxuanzexuanxiang: "Menu Options",
  shifoukeshuruduogeneirong: "Can add text input box?",
  shifoujinzhishangchuanfujian: "Disable File Upload?",
  bianjirenwumokuaixinxi: "Edit Template Module Info",
  xinzengrenwumokuaixinxi: "Add a New Template Module",
  yiji_note:
    "Level One is the highest level or the root level of the framework",
  erji_note:
    "By default subordinated to the nearest Level One module before it",
  sanji_note:
    "By default subordinated to the nearest Level Two module before it",

  blank_note:
    "This type of module will not ask students to enter anything, but will only work as a prompt (esp. when it has lower-level modules that require student input and the module itself is more of a structural summary). When this type is selected, students will not be able to enter content under this module.",
  textarea_note:
    "Multi-line text input, where students can enter paragraphs of text for detailed descriptions or answers",
  input_note:
    "A single line of text input, where students can enter a short answer within one line of text",
  select_note:
    "Provide a drop-down menu and a limited number of options for students to choose from and standardize the information or answers they can submit",
  file_note:
    "Specify that students are required to upload files only and no other input can be made",
  check_note:
    "Specify that students are required to only do quality checks in this module without any other input",
  multi_input_note:
    "Whether to allow students to repeatedly add the same input box to submit or enumerate multiple, equivalent types of information to show a clearer structure",
  multi_input_yes_note: "Allow students to add more input boxes on their own",
  multi_input_no_note: "Students can only fill in one input box",
  instruction_note:
    "Instructional text for this module may include introducing what the module is, explaining why the module should be completed, briefly describing how to complete the module, etc.",
  deliverable_note:
    "Clearly describe what students need to deliver under this module, to help students clarify their assignment objectives and prevent students from being unclear or confused about what is to be delivered;",
  disable_file_upload_note:
    'By default, all modules in this system allow students to upload files or materials under the module according to their needs, if you need to prohibit students from uploading under this module, please select "Yes"',
  block_name_placeholder: "Please input the module name",
  block_instruction_placeholder: "Please input the module instruction",
  block_deliverable_placeholder: "Please input the module deliverable",
  module_search_placeholder: "Input Module Name Keywords",
  yunxushangchuanfujian: "File upload allowed",
  keyishuruduogeneirong: "Can add input boxes",
  zuidazishu: "Max Length",
  zuishaozishu: "Min Length",
  min_length_note:
    "The minimum number of words per input box students are required to enter; leave it blank if no minimum requirement",
  max_length_note:
    "The maximum number of words per input box students can enter; leave it blank if no maximum requirement",
  paixu: "Sort Modules",
  mobantouxiang: "Template Avatar",
  mobanmingcheng: "Template Name",
  mobanjianjie: "Template Introduction",
  mobanjianjie_placeholder: "Please input the template introduction",
  mobanmingcheng_placeholder: "Please input the template name",
  xiaoxizhongxin: "Messages",
  qiehuanyuyan: "Languages",
  xiugaitouxiang: "Change Avatar",
  xiugaigerenmima: "Edit Personal Password",
  xiugaiyouxiang: "Change Email",
  xiugaichenggong: "Saved Successfully",
  xiugaimima: "Change Password",
  zhanghaoxinxi: "Account Info",
  tuichudenglu: "Logout",
  xuesheng: "Student",
  xuexiaozhidaolaoshi: "Adviser",
  xuexiaoguanliyuan: "Account Admin",
  guanliyuan: "System Admin",
  dengluchenggong: "Login Success",
  qingshuruzhanghao: "Please input your account",
  qingshurumima: "Please input your password",
  qingxuanzedengluleixing: "Please select your login type",
  quedingqiehuanyuyan: "Do you want to switch language?",
  qingxianxiugaimima: "Please go to change your password first.",
  youxianghuomimacuowu: "Your account or password is wrong",
  biaodanmeitianwanzheng: "Please fill in all blanks before submission",
  qiehuanxitongyuyan: "Switch System Language",
  qingxuanze: "Please select",
  jiumima: "Old Password",
  xinmima: "New Password",
  qingshurujiumima: "Please input the old password",
  qingquerenxinmima: "Please confirm the new password",
  querenmima: "Confirm Password",
  queren: "Confirm",
  fasongxiaoxi: "Send A Message",
  fasong: "Send",
  yifasong: "Sent",
  xitonghuifu: "System Reply",
  xinyouxiang: "New Email",
  qingshuruxinyouxiang: "Please input the new email",
  quanbu: "All",
  benxiaozijianmoban: "Original Templates",
  benxiaogoumaimoban: "Purchased Templates",
  benxiaozijianmoban_mark: "Original Template",
  benxiaogoumaimoban_mark: "Purchased Template",
  xinzengmoban: "New Template",
  qiehuanzhiliebiaoshitu: "Switch to List View",
  qiehuanzhisuoluetushitu: "Switch to Thumbnail View",
  gengxinchenggong: "Update Success",
  xinzengchenggong: "Added Successfully",
  xinzengjianchaxiang: "Add Check Item",
  bianjijianchaxiang: "Edit Check Item",
  weidupaixu: "Sort Criteria",
  tianjiaweidu: "Add a Criterion",
  zongji: "Total",
  fenzhi: "Points",
  weidufenshu: "Points for this criterion",
  weidumingcheng: "Criterion Name",
  weidujianjie: "Criterion Explanation",
  weidumingcheng_placeholder: "Please input the criterion name",
  weidujianjie_placeholder: "Please input the criterion explanation",
  weidudefen_placeholder: "Please input the points for this criterion",
  pingfenbiaomingcheng: "Evaluation Form Name",
  pingfenbiaomingcheng_placeholder:
    "Please input the name of the evaluation Form",
  xiugaipingfenbiaomingcheng: "Edit Evaluation Form Name",
  mokuaijibenxinxi: "Module Basic Information",
  zanwushuju: "No Data",
  shangchuan: "Upload",
  jinggao: "Alert",
  shifouchuangjianpingfenbiao:
    "No evaluation form has been created for this template. Create a new one?",
  shangchuanchenggong: "Upload Success",
  xiugaichenggong: "Edited Successfully",
  paixuchenggong: "Sorted Successfully",
  yaoqiuleixing: "Check Item Type",
  yaoqiuneirong: "Check Item Content",
  check_content_placeholder:
    "Please input the detailed description of the check time",
  check_type_placeholder: "Please select a type of area for the check item",
  xuhao: "No.",
  ziyuanmingcheng: "Resource Name",
  ziyuanleixing: "Resource Format",
  ziyuanlianjie: "Resource Link",
  shangchuanshijian: "Upload Time",
  shangchuanfangshi: "Upload Channel",
  bendishangchuan: "Local File",
  waibulianjie: "External Link",
  xinzengxiangmumoban: "Create A New Project Template",
  bianjixiangmumoban: "Edit Project Template",
  xiangmumobanmingbunengweikong: "Template name cannot be blank",
  xiangmumobanmiaoshubunengweikong: "Template introduction cannot be blank",
  xuexiaoguanliyuan: "Account Admin",
  ziliao: "Information",
  PGxueyuan: "PG Academy",
  xiangmuming: "Project template name",
  xinzeng: "Add",
  xueke: "Subject",
  bumen: "Department",
  youxiang: "Email",
  xingbie: "Gender",
  zhiwei: "Position",
  zaizhizhuangtai: "Employed?",
  yingwenming: "Name in English",
  jiaoshixingming: "Teacher's Name",
  xinzengjiaoshixinxi: "Add a New Teacher",
  bianjijiaoshixinxi: "Edit Teacher Info",
  xing: "Last Name",
  ming: "Given Name",
  xiansheng: "Male",
  nvshi: "Female",
  qingshuruxing: "Please input last name",
  qingshuruming: "Please input given name",
  qingshuruyingwenming: "Please input name in English",
  qingxuanzebumen: "Please select department",
  qingshuruzhiwei: "Please input position",
  qingshuruyouxiang: "Please input email",
  qingxuanzexueke: "Please select subject",
  sousuojiaoshixingming: "Search teacher's name",
  tianjiabumen_note: "You can add department first in the setting section.",
  tianjiaxueke_note: "You can add subject first in the setting section.",
  genghuanmobantouxiang: "Change Template Avatar",
  dianjianshangchuan: "Click to upload",
  xiazai: "Download",
  fangda: "Enlarge",
  suoxiao: "Downsize",
  genghuantupian: "Change Picture",
  zuoxuanzhuan: "Rotate left",
  youxuanzhuan: "Rotate right",
  qingchutupian: "Clear",
  caijiantupian: "Crop Image",
  tupianshangchuan_note: "JPG/PNG format only; image size 2MB at max.",
  功能: "Function Update",
  商店: "Template Store Update",
  学院: "PG Academy Update",
  服务: "Service Update",
  banjimingcheng: "Class Name",
  banjirenshu: "# of Students",
  xuenian: "Academic Year",
  suoshulaoshi: "Assigned Teachers",
  chakan: "View",
  qingxuanzelaoshi: "Please assign a teacher",
  qingxuanzexuenian: "Please select the academic year",
  qingshurubanjimingcheng: "Please input the class name",
  xinzengbanjixinxi: "Add a New Class",
  bianjibanjixinxi: "Edit Class Info",
  banjiguanli: "Class Admin",
  先生: "Male",
  女士: "Female",
  beizhu: "Note",
  biyenianfen: "Expected Year of Graduation",
  xueshengxingming: "Student Name",
  xingyingwen: "Last Name (En)",
  mingyingwen: "Given Name (En)",
  nan: "Male",
  nv: "Female",
  男: "Male",
  女: "Female",
  zhanghukaiqizhuangtai: "Account Enabled?",
  xueshengsuoshubanji: "in Classes",
  xinzengxueshengxinxi: "Add a New Student",
  bianjixueshengxinxi: "Edit Student Info",
  qingshurubeizhu: "Please input the note",
  qingshuruxingyingwen: "Please input the last name in English",
  qingshurumingyingwen: "Please input the given name in English",
  qingxuanzebiyenianfen: "Please select expected year of graduation",
  jibenxinxi: "Basic Information",
  jiaoshixinxiguanli: "Teacher Management",
  jiaoshiliebiao: "Teacher List",
  xueshengxinxiguanli: "Student List",
  banjiguanli: "Class List",
  xueshengliebiao: "Student List",
  benxiaoxiangmuguanli: "Projects Management",
  zaizuoxiangmu: "Ongoing Projects",
  wanjiexiangmu: "Finished Projects",
  mobankuguanli: "Template Management",
  benxiaomobanku: "My Templates",
  xitongshezhi: "System Settings",
  guanlishezhi: "Admin Setting",
  xuexiaodingdan: "Orders",
  bumenshezhi: "Department Setting",
  xuekeshezhi: "Subject Setting",
  dangxiaxuenianshezhi: "Current Academic Year",
  bumen_placeholder: "Please add departments",
  xueke_placeholder: "Please add subject",
  xuenian_placeholder: "Please select the current academic year",
  bumen_note:
    "Enter the department name and press the Enter key to confirm the addition",
  xueke_note:
    "Enter the sbuject name and press the Enter key to confirm the addition",
  dangqianxuenian_note: "Please select the current academic year",
  xuexiaomingcheng: "School Name",
  xuexiaoyingwenming: "School Name (En)",
  xuexiaologo: "School LOGO",
  guojia: "Country",
  xuexiaodizhi: "School Address",
  xuexiaoyingwendizhi: "School Address (En)",
  youbian: "Postcode",
  xuexiaoleixing: "Type",
  xuexiaoxueduan: "Age Category",
  xuexiaowangzhi: "Website",
  buchongxinxi: "Notes",
  xiangmuzhuangtai: "Project Status",
  xiangmunianfen: "Project Year",
  xueshengfenzu: "Student Teams",
  xiangmumingcheng: "Project Name",
  xiangmuzhouqi: "Project Period",
  fenpeijiaoshi: "Assigned Teachers",
  fenpeibanji: "Assigned Classes",
  shifoukaifangbaoming: "Registration Open?",
  xiangmupici: "Project Batch",
  xinzengfenzu: "Add a Team",
  weifenzuxuesheng: "Unteamed Students",
  fenzu: "Team Up",
  weifenzurenshu: "# of Unteamed Students",
  xueshengxingming: "Student Name",
  zuhao: "Team #",
  xueshengmingdan: "Member List",
  wanchengjindu: "Project Progress",
  jianchajindu: "Check Progress",
  zishuchangdu: "Length of Submitted Content (Word Count)",
  pingyushuliang: "# of Comments",
  huizong: "Summary",
  jianchazongbiao: "Checklist",
  xiugaifenzu: "Modify Team",
  pingfen: "Rating",
  pingjiang: "Awarding",
  shengchengPDFbaogao: "Save as PDF",
  xiugaifenzuxinxi: "Modify Team Info",
  xiaozumingcheng: "Team Name",
  xiangmuxiaozu: "Team",
  qingxuanzezuocemokuai: "Please select a module on the left",
  tianjiapinglun: "Add a Comment",
  laoshipinglun: "Teacher Comments",
  wu: "None",
  zuijinxiugaishijian: "Latest Update Time",
  tijiao: "submit",
  benkechengyou: "This course has ",
  daosuitanglianxi: " quiz questions",
  xueshengzuoda: "Student Answers",
  quchushurukuang: "Remove Input Box",
  qingshuruneirong: "Please input answers here",
  meiyoujilu: "No Records",
  xiazaipdf: "Download PDF",
  jijianggengxin: "Coming Soon",
  shipinbofang: "Video Play",
  liulanqibuzhichishipinbofang: "Your browser does not support video play",
  fujianmingcheng: "Attachment Name",
  fujiandizhi: "Attachment Link",
  fujianshuoming: "Attachment Description",
  shangchuanshezhi: "Upload Setting",
  shangchuanleixing: "Upload Type",
  shangchuanleixing_placeholder: "Please select upload type",
  fujianmingcheng_placeholder: "Please input attachment name",
  fujiandizhi_placeholder: "Please input attachment address",
  fujianshuoming_placeholder: "Please input attachment description",
  wenjianshangchuandaxiaoxianzhi:
    "The size of your file to upload cannot exceed 10MB",
  xiugai: "Change",
  gaimokuaibuyunxushangchuanfujian: "This module does not allow file upload",
  mokuaijishu: "Modules",
  checklistjishu: "Check Items",
  pingfenbiaojishu: "Rating Criteria",
  ziliaojishu: "Resources",
  yigoumai: "Purchased",
  weigoumai: "Not Purchased Yet",
  fanhui: "Back",
  dinggou: "Order",
  高级版: "Premium Version",
  基本版: "Basic Version",
  精装版: "Deluxe Version",
  全面版: "Full Version",
  wozhidaole: "Got it",
  liaojiemobanbutongbanben: "Know Different Versions of Project Templates",
  高级版_sub: "Basic Version + Standard Checklist",
  基本版_sub: "Provides Basic Template Framework and Modules",
  精装版_sub: "Premium Version + Standard Evaluation Form",
  全面版_sub: "Deluxe Version + Necessary Resources and References",
  高级版_intro:
    "The Premium Version provides additional standard check items for each module on top of the basic version, which is convenient for students to check themselves and teachers to review, so that project quality can be guaranteed.",
  基本版_intro:
    "The Basic Version provides the basic building modules and structure in the template framework, allowing schools and students to quickly get started with the project. Each module has a basic textual guide.",
  精装版_intro:
    "The Deluxe Version provides an additional standard evaluation form for projects on top of the premium version so that teachers can assess and evaluate student projects",
  全面版_intro:
    "The Full Version provides additional reference documents/materials or videos for the project based on the deluxe version, so that students can refer to and learn from them during the project.",
  jianchaxiang: "Checklist",
  pinggubiao: "Evaluation Form",
};
